<template>
    <router-view></router-view>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
export default {
    name: 'App',
    components: {},
    data() {
        return {
            intervalId: null,
            timeoutID: null,
            idleDuration: 3600000,
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated']),
    },
    watch: {
        '$i18n.locale'() {
            this.checkLocale();
        },
        authenticated(n) {
            if (!n) {
                this.stopInterval();
            } else {
                this.startInterval();
            }
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
            authLogout: 'logoutUser',
        }),
        ...mapMutations('auth', ['destroyAuth']),
        checkLocale() {
            if (this.$i18n.locale == 'ko') {
                document.body.classList.add('ko');
                document.body.classList.remove('en');
            } else {
                document.body.classList.add('en');
                document.body.classList.remove('ko');
            }
        },
        async checkCookieTime() {
            var date = new Date();
            $cookies.set('cookieTime', date.getTime());
        },
        async profileInterval() {
            await this.authGetProfile();
        },
        startInterval() {
            this.intervalId = setInterval(this.profileInterval, 10000);
        },
        stopInterval() {
            clearInterval(this.intervalId);
        },
        resetTimer() {
            clearTimeout(this.timeoutID);
            this.timeoutID = setTimeout(this.callLogout, this.idleDuration);
        },
        callLogout() {
            if (this.authenticated && process.env.NODE_ENV == 'production') {
                this.authLogout();
            }
        },
    },
    mounted() {
        document.body.classList.add('ko');
        var loader = document.querySelector('.static-loader');
        loader.remove();
        var date = new Date();
        var currentDateTime = date.getTime();
        var cookieTime = $cookies.get('cookieTime');
        var cookieDateInSecs = Math.round(cookieTime / 1000);
        var currentDateInSecs = Math.round(currentDateTime / 1000);
        var totalSeconds = currentDateInSecs - cookieDateInSecs;
        var vm = this;
        if (vm.authenticated) {
            if (totalSeconds > 10 && !window.opener) {
                // this.destroyAuth();
            }
            vm.startInterval();
        }

        if (process.env.NODE_ENV == 'production') {
            if (window.opener == null) {
                this.$i18n.locale = 'ko';
                $cookies.set('locale', 'ko');
            } else {
                this.$i18n.locale = $cookies.get('locale');
            }
        } else {
            if ($cookies.get('locale')) {
                this.$i18n.locale = $cookies.get('locale');
            }
        }


        window.BroadcastChannel = new BroadcastChannel("notif_channel");

        window.BroadcastChannel.addEventListener("message", (event) => {
            if(event.data?.notifId) {
                vm.$store.commit('removeNotificationById', event.data?.notifId);
            }
        });

        // this.resetTimer();
        // document.addEventListener('mousemove', this.resetTimer);
        // document.addEventListener('keypress', this.resetTimer);
    },
    beforeUnmount() {
        // this.stopInterval();
        // document.removeEventListener('mousemove', this.resetTimer);
        // document.removeEventListener('keypress', this.resetTimer);
    },
};

// if (window.opener == null) {
//     window.addEventListener('beforeunload', (event) => {
//         event.preventDefault();
//         event.returnValue = ''; // This is for older browsers.
//         this.destroyAuth();
//     });
// }
// document.addEventListener('keydown', function (event) {
//     if (
//         event.key === 'F5' ||
//         event.key === 'Refresh' ||
//         (event.ctrlKey && event.key === 'r')
//     ) {
//         event.preventDefault(); // Prevent the default F5 refresh behavior
//         // Perform your custom refresh logic here
//         console.log('Custom refresh action triggered');
//         // You can put your custom refresh logic here, such as reloading data or updating the page.
//     }
// });
</script>
