import $api from "../api";
import router from "../../router/index";
import $cookies from "vue-cookies";
import Swal from "sweetalert2";
import Pusher from 'pusher-js'


var pusher
// if ($cookies.get("token")) {
//     pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
//         cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
//     });
// }
export const state = {
    token: $cookies.get("token"),
    type: 1,
    id: 1,
    profile: {
        loading: true,
        routeTo: null,
        username: null,
        status: null,
        last_login_ip: null,
        last_login_at: "",
        notification: {
            offline_player_deposit_count: 3,
            offline_player_withdraw_count: 2,
            agent_deposit_count: 1,
            agent_withdraw_count: 0,
            online_player_deposit_count: 2,
            online_player_withdraw_count: 0,
            new_player_count: 0,
            deleted_player_count: 0,
            agent_ticket_unread_count: 0,
            player_ticket_unread_count: 0,
            open_player_ticket_count: 0,
            open_agent_ticket_count: 0,
            ticket_today_count: 0,
            pending_player_count: 0,
            pending_agent_count: 0,
            total_transaction_count: 0,
            pending_bet_count: 0
        },
        summary: {
            total_agent_balance: 0,
            total_player_balance: 0,
            online_player_deposit: 0,
            online_player_withdraw: 0,
            online_agent_deposit: 0,
            online_agent_withdraw: 0,
            total_income: 0,
            new_player: 0,
            live_player: 0,
            login_player: 0,
            honorlink_balance: 0,
            kplay_balance: 0
        },
        readonly: null
    }
};

export const actions = {
    loginUser({ commit }, pl) {
        return new Promise(function (resolve, reject) {
            $api
                .post("auth/admin/login", pl)
                .then(function (res) {
                    if (res.status === 200) {

                        commit("setToken", res.data.data);
                        resolve({
                            success: true,
                            message: ""
                        });
                    }
                })
                .catch(function (err) {
                    var err_msg
                    if (err.response) {
                        err_msg = err.response.data.message ? err.response.data.message : "Invalid credentials";
                    }
                    resolve({
                        success: false,
                        message: err_msg
                    });
                    reject(err)
                });
        });
    },
    updateProfile({ getters }, pl) {
        return new Promise(function (resolve, reject) {
            $api
                .post("auth/profile", pl, {
                    headers: { Authorization: getters.bearer_token },
                })
                .then(function (res) {
                    if (res.status === 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    logoutUser({ commit, getters }) {
        return new Promise(function (resolve) {
            $api
                .get("auth/logout", {
                    headers: { Authorization: getters.bearer_token },
                })
                .then(function () {
                    commit("destroyAuth");
                    resolve(true);
                })
                .catch(function () {
                    commit("destroyAuth");
                    resolve(true);
                });
        });
    },
    getProfile({ commit, getters }) {
        return new Promise(function (resolve) {
            $api
                .get(
                    "auth/profile", {
                    headers: { Authorization: getters.bearer_token },
                }
                )
                .then(function (res) {
                    if (res.status === 200) {
                        var date = new Date();
                        $cookies.set('cookieTime', date.getTime());
                        commit("setProfile", res.data.data);
                    }
                    resolve(true);
                })
                .catch(function (err) {
                    console.log(err);
                    if (err.response.status == 401) {
                        commit("destroyAuth");
                    }
                    resolve(false);
                });

        });
    },
};

export const mutations = {
    triggerLoader(state, value) {
        state.profile.loading = Boolean(value)
        state.profile.routeTo = value ? value : null
    },
    setToken(state, data) {
        var token = data.access_token //`${data.token_type} ${data.access_token}`;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        $cookies.set("token", token, expirationDate);
        state.token = token;
        pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        });
        window.PusherChannel = pusher.subscribe(token.split('|')[0]);
    },
    setProfile(state, data) {
        if (!Boolean(window.PusherChannel)) {
            pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            });
            window.PusherChannel = pusher.subscribe(state.token.split('|')[0]);
        }
        state.profile.username = data.username;
        state.profile.readonly = data.readonly;
        state.profile.status = data.status;
        state.profile.last_login_at = data.last_login_at;
        state.profile.last_login_ip = data.last_login_ip;

        state.profile.notification.offline_player_deposit_count = data.notification.offline_player_deposit_count
        state.profile.notification.offline_player_withdraw_count = data.notification.offline_player_withdraw_count
        state.profile.notification.agent_deposit_count = data.notification.agent_deposit_count
        state.profile.notification.agent_withdraw_count = data.notification.agent_withdraw_count
        state.profile.notification.online_player_deposit_count = data.notification.online_player_deposit_count
        state.profile.notification.online_player_withdraw_count = data.notification.online_player_withdraw_count

        state.profile.notification.new_player_count = data.notification.new_player_count
        state.profile.notification.deleted_player_count = data.notification.deleted_player_count
        state.profile.notification.agent_ticket_unread_count = data.notification.agent_ticket_unread_count
        state.profile.notification.player_ticket_unread_count = data.notification.player_ticket_unread_count
        state.profile.notification.open_player_ticket_count = data.notification.open_player_ticket_count
        state.profile.notification.open_agent_ticket_count = data.notification.open_agent_ticket_count
        state.profile.notification.ticket_today_count = data.notification.ticket_today_count
        state.profile.notification.pending_player_count = data.notification.pending_player_count
        state.profile.notification.pending_agent_count = data.notification.pending_agent_count
        state.profile.notification.pending_bet_count = data.notification.pending_bet_count
        state.profile.summary.total_agent_balance = data.summary.total_agent_balance
        state.profile.summary.total_player_balance = data.summary.total_player_balance
        state.profile.summary.online_player_deposit = data.summary.online_player_deposit
        state.profile.summary.online_player_withdraw = data.summary.online_player_withdraw
        state.profile.summary.online_agent_deposit = data.summary.online_agent_deposit
        state.profile.summary.online_agent_withdraw = data.summary.online_agent_withdraw
        state.profile.summary.total_income = data.summary.total_income
        state.profile.summary.new_player = data.summary.new_player
        state.profile.summary.live_player = data.summary.live_player
        state.profile.summary.login_player = data.summary.login_player
        state.profile.summary.honorlink_balance = data.summary.honorlink_balance
        state.profile.summary.kplay_balance = data.summary.kplay_balance

        state.profile.notification.total_transaction_count =
            data.notification.offline_player_deposit_count +
            data.notification.offline_player_withdraw_count +
            data.notification.online_player_deposit_count +
            data.notification.online_player_withdraw_count +
            data.notification.agent_deposit_count +
            data.notification.agent_withdraw_count
    },
    destroyAuth(state) {
        $cookies.remove("token");
        $cookies.remove('cookieTime');
        // if (Boolean(window.PusherChannel)) {
        if (Boolean(pusher) && Boolean(state.token)) {
            pusher.unsubscribe(state.token.split('|')[0]);
        }
        state.token = null;
        state.profile.username = null;
        state.profile.readonly = null;
        state.profile.status = null;
        state.profile.last_login_at = null;
        state.profile.last_login_ip = null;
        state.profile.notification.offline_player_deposit_count = 0
        state.profile.notification.offline_player_withdraw_count = 0
        state.profile.notification.agent_deposit_count = 0
        state.profile.notification.agent_withdraw_count = 0
        state.profile.notification.online_player_deposit_count = 0
        state.profile.notification.online_player_withdraw_count = 0
        state.profile.notification.deleted_player_count = 0
        state.profile.notification.new_player_count = 0
        state.profile.notification.agent_ticket_unread_count = 0
        state.profile.notification.player_ticket_unread_count = 0
        state.profile.notification.open_player_ticket_count = 0
        state.profile.notification.open_agent_ticket_count = 0
        state.profile.notification.ticket_today_count = 0
        state.profile.notification.pending_player_count = 0
        state.profile.notification.pending_agent_count = 0
        state.profile.notification.pending_bet_count = 0

        state.profile.summary.total_agent_balance = 0
        state.profile.summary.total_player_balance = 0
        state.profile.summary.online_player_deposit = 0
        state.profile.summary.online_player_withdraw = 0
        state.profile.summary.online_agent_deposit = 0
        state.profile.summary.online_agent_withdraw = 0
        state.profile.summary.total_income = 0
        state.profile.summary.new_player = 0
        state.profile.summary.live_player = 0
        state.profile.summary.login_player = 0
        state.profile.summary.honorlink_balance = 0
        state.profile.summary.kplay_balance = 0
        router.replace({ path: "/login" }).catch(() => { });
    }
};
export const getters = {
    authenticated(state) {
        return state.token != null;
    },
    bearer_token(state) {
        return 'Bearer ' + state.token;
    },
    pusher_channel(state) {
        return state.token.split('|')[0];
    },
    profile(state) {
        return state.profile;
    }
};